import { gql } from '@apollo/client';
import { User } from 'firebase/auth';
import { CollectionReference, collection } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';

import { Game3 } from './firestoreTypes';
import { db } from '..';

export const stage3Schema = gql`
  mutation SubmitAnswer3($input: Answer3!) {
    submitAnswer3(input: $input) {
      score
      correct
    }
  }
`;

export type Stage3Submission = Game3;

export const useStage3Submission = (
  user: User | null,
  eventId: string | null
): LoadingHook<Stage3Submission[], Error> => {
  return useCollectionData<Stage3Submission>(
    user !== null && eventId !== null
      ? (collection(
          db,
          'version/1/submissions',
          eventId,
          user.uid,
          '3',
          'problem_id'
        ) as CollectionReference<Stage3Submission>)
      : undefined
  );
};

export const useStage3SubmissionByUID = (
  uid: string | null,
  eventId: string | null
): LoadingHook<Stage3Submission[], Error> => {
  return useCollectionData<Stage3Submission>(
    uid !== null && eventId !== null
      ? (collection(
          db,
          'version/1/submissions',
          eventId,
          uid,
          '3',
          'problem_id'
        ) as CollectionReference<Stage3Submission>)
      : undefined
  );
};
