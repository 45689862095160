import { gql } from '@apollo/client';
import {
  DocumentReference,
  Query,
  collection,
  doc,
  query,
  where,
} from 'firebase/firestore';
import {
  DocumentDataHook,
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';

import { UserInfo, UserOrgInfo } from '../api/firestoreTypes';
import { TS2DateType } from '../lib/firebase/firestoreTypeUtils';
import { Maybe } from '../types';
import { UserInfo as UserDocumentRaw } from './firestoreTypes';
import { db } from '..';

export const userSchema = gql`
  query validateRegistrationToken($input: ValidateRegistrationTokenInput!) {
    validateRegistrationToken(input: $input) {
      organizationName
    }
  }

  mutation register($input: RegisterInput!) {
    register(input: $input) {
      uid
    }
  }

  mutation resendRegisterMail($input: ResendRegisterMailInput!) {
    resendRegisterMail(input: $input) {
      success
    }
  }

  mutation registerUserInfo($input: RegisterUserInfoInput!) {
    registerUserInfo(input: $input) {
      success
    }
  }

  mutation updateUserInfo($input: UpdateUserInfoInput!) {
    updateUserInfo(input: $input) {
      success
    }
  }

  mutation updateEmail($input: EmailInput!) {
    updateEmail(input: $input) {
      success
    }
  }

  mutation confirmUpdateEmail($input: EmailInput!) {
    confirmUpdateEmail(input: $input) {
      success
    }
  }
  mutation changeToVerifiedEmail($input: EmailInput!) {
    changeToVerifiedEmail(input: $input) {
      success
    }
  }
  mutation cancelUpdateEmail($input: EmailInput!) {
    cancelUpdateEmail(input: $input) {
      success
    }
  }
`;
export type UserDocumentNoId = TS2DateType<UserDocumentRaw>;
export type UserDocument = UserDocumentNoId & {
  uid: string;
};

export const useUserInfo = (
  userId?: Maybe<string>
): DocumentDataHook<UserInfo> => {
  const docRef =
    userId != null
      ? (doc(db, 'version/1/user_info', userId) as DocumentReference<UserInfo>)
      : undefined;
  return useDocumentData<UserInfo>(docRef);
};

export const useUserOrgInfo = (
  userId?: Maybe<string>,
  orgId?: Maybe<string>
): DocumentDataHook<UserOrgInfo> => {
  const docRef =
    userId != null && orgId != null
      ? (doc(
          db,
          'version/1/user_info',
          userId,
          'organizations',
          orgId
        ) as DocumentReference<UserOrgInfo>)
      : undefined;
  return useDocumentData<UserOrgInfo>(docRef);
};

const mapper = (raw: UserDocumentRaw): UserDocumentNoId => ({
  ...raw,
  birthday: raw.birthday?.toDate() ?? null,
  createdOn: raw.createdOn?.toDate() ?? null,
});

export const useAllUserInfoWithOrgId = (orgId?: string | null) => {
  return useCollectionData<UserDocumentNoId, 'uid'>(
    orgId
      ? (query(
          collection(db, 'version/1/user_info'),
          where('enable', '==', true),
          where('mainOrgId', '==', orgId)
        ) as Query<UserDocumentNoId>)
      : undefined,
    {
      transform: mapper,
      idField: 'uid',
    }
  );
};

export const useAllAdminUserInfo = () => {
  return useCollectionData<UserDocumentNoId, 'uid'>(
    query(
      collection(db, 'version/1/user_info'),
      where('enable', '==', true),
      where('commonRoles', 'array-contains-any', [
        'riddlerAdmin',
        'dentsuAdmin',
        'organizationAdmin',
      ])
    ) as Query<UserDocumentNoId>,
    {
      transform: mapper,
      idField: 'uid',
    }
  );
};
